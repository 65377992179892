<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Downloads</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

		<!-- downloads area start -->
      <section class="downloads__area pt-30 pb-200 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-7 d-none d-lg-block">
                  <p v-html="sourceDownloads.descricao"></p>
               </div>
               <div class="col-lg-5">
                  <div class="input-group">
                     <i class="far fa-search input-group-text" @click="searchDownloads"></i>
                     <input type="text" class="form-control" placeholder="Buscar" v-model="search.valor" @keyup.enter="searchDownloads">
                  </div>
               </div>

               <div class="col-12 mt-30 mb-30">
                  <div class="product__details-des-tab tab-download">
                     <ul class="nav nav-tabs justify-content-center" id="productDesTab" role="tablist">
                        <li v-if="search.result.manuais.length > 0" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-one" data-bs-target="#one" data-bs-toggle="tab" type="button" role="tab" aria-controls="one"
                              :class="tabAtivo == 'tab-one' ? 'active' : ''" :aria-selected="tabAtivo == 'tab-one' > 0 ? true : false">
                              <span class="limitador">Manuais</span>
                           </button>
                        </li>
                        <li v-if="search.result.folhetos.length > 0" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-two" data-bs-target="#two" data-bs-toggle="tab" type="button" role="tab" aria-controls="two"
                              :class="tabAtivo == 'tab-two' ? 'active' : ''" :aria-selected="tabAtivo == 'tab-two' ? true : false">
                              <span class="limitador">Folhetos</span>
                           </button>
                        </li>
                        <li v-if="search.result.catalogos.length > 0" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-three" data-bs-target="#three" data-bs-toggle="tab" type="button" role="tab" aria-controls="three"
                              :class="tabAtivo == 'tab-three' ? 'active' : ''" :aria-selected="tabAtivo == 'tab-three' ? true : false">
                              <span>Catálogos</span>
                           </button>
                        </li>
                     </ul>
                  </div>
               </div>

               <div class="col-12 mb-30">
                  <div class="tab-content" id="prodductDesTaContent">
                     <div class="tab-pane fade" id="one" role="tabpanel" aria-labelledby="tab-one" :class="tabAtivo == 'tab-one' ? 'show active' : ''"
                        v-if="search.result.manuais.length > 0">
                        <div class="cards">
                           <arquivo v-for="(arquivo, index) in search.result.manuais" :key="'Manuais'+ index" :arquivo="arquivo" :view="'Downloads-Manuais'" 
                              @download="download($event)" />
                        </div>
                     </div>
                     <div class="tab-pane fade" id="two" role="tabpanel" aria-labelledby="tab-two" :class="tabAtivo == 'tab-two' ? 'show active' : ''"
                        v-if="search.result.folhetos.length > 0">
                        <div class="cards">
                           <arquivo v-for="(arquivo, index) in search.result.folhetos" :key="'Folhetos'+ index" :arquivo="arquivo" :view="'Downloads-Folhetos'" 
                              @download="download($event)" />
                        </div>
                     </div>
                     <div class="tab-pane fade" id="three" role="tabpanel" aria-labelledby="tab-three" :class="tabAtivo == 'tab-three' ? 'show active' : ''"
                        v-if="search.result.catalogos.length > 0">
                        <div class="cards">
                           <arquivo v-for="(arquivo, index) in search.result.catalogos" :key="'Catalogos'+ index" :arquivo="arquivo" :view="'Downloads-Catalogos'" 
                              @download="download($event)" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import { mapState } from 'vuex'
import arquivo from '@/components/downloads/Arquivo.vue'

export default {
   name: 'Downloads',
   data : function () {
      return {
         tabAtivo: 'tab-one',
         search: {'valor': '', 'isTyping': false, 'result': {'manuais': [], 'folhetos': [], 'catalogos': [], 'revistas': [], 'apoio': {'manuais': [], 'paineis': [], 'banners': [], 'logos': []}}}
      }
   },
   computed: {
		... mapState({
         sourceDownloads: state => state.source.downloads,
			urlRest: state => state.urlRest
		})
	},
   components: {
      arquivo
   },
   methods: {
      searchDownloads : function () {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/searchDownloads',
            params: {
               valor: this.search.valor
            }
			}).then(response => {
            this.search.result = response.data

            if (response.data.manuais.length > 0) {
               this.tabAtivo = 'tab-one';
            } else {
               if (response.data.folhetos.length > 0) {
                  this.tabAtivo = 'tab-two';
               } else if (response.data.catalogos.length > 0) {
                  this.tabAtivo = 'tab-three';
               }
            }
			}).catch(function (error) {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						ref.$store.dispatch('deslogar')

						ref.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						ref.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					ref.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      download : function (arquivo) {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: arquivo.urlDownload,
            responseType: 'blob'

         }).then(function (response) {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', arquivo.titulo);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            ref.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
   },
   mounted() {
      this.searchDownloads()
   }
}

</script>

<style scoped>

#prodductDesTaContent .info-header {
   text-align: center;
   width: 767px;
   max-width: 100%;
   margin: 0 auto;
}

.downloads__area .input-group .input-group-text {
   background: #fff;
   border-color: #ebebeb;
   color: #aaa;
   font-size: 13px;
   padding-top: 13px;
   transform: none;
   cursor: pointer;
}

.downloads__area .input-group .form-control {
   border-left: 0;
}

</style>